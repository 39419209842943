import React from 'react'

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
// import Button from '@material-ui/core/Button';
// import Link from '@material-ui/core/Link';


import logo from '../logo.png'
// import QIDlogo from '../QIDlogo.png'


import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import Hidden from '@material-ui/core/Hidden';

import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


function NavBar({ loggedIn, adminPresent, logoutClickHandler, history }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const anchorRef = React.useRef(null);


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    return <React.Fragment>
    <AppBar style={{display: "flex", backgroundColor: '#393e41', height: '90px', padding: '20px 0px'}} >
        <Toolbar variant="dense" style={{height: '50px', justifyContent: 'space-between'}}>
          <img onClick={() => history ? history.push("/") : ''} src={logo} id="logo" alt="QID logo"/>
          
          {/* <Hidden mdUp>
            
            <Typography display="inline" style={{fontSize: '30px', fontFamily: 'futura', fontWeight: 'bold'}}>QID
            
            </Typography>
            <div id="logoContainer" style={{display: 'flex', alignItems: 'center'}}>
            <img id="QIDlogo" onClick={() => history ? history.push("/") : ''} src={QIDlogo} alt="QID logo"/>
            <div>
            <Typography className="logoSubtitle" style={{fontSize: '18px', fontWeight: 'bold'}}>Truth.</Typography>
            <Typography className="logoSubtitle"style={{fontSize: '18px', fontWeight: 'bold'}}>Everywhere.</Typography>
            <Typography className="logoSubtitle" style={{fontSize: '18px', fontWeight: 'bold'}}>Fast.</Typography>
            </div>
            </div>
            
          </Hidden> */}


          <Hidden smDown>
            <div>
            <Typography style={{fontSize: '30px', fontWeight: 'bold'}}>Quality Intelligence & Documentation</Typography>
            {/* <Typography style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>Truth. Everywhere. Fast.</Typography> */}
            </div>
            
          </Hidden>
          
          {loggedIn ? <div>
          <IconButton ref={anchorRef} onClick={handleMenu} aria-label="display more actions" edge="end" color="inherit">
            <MoreIcon />
          </IconButton> 
          <Menu
          className="NavBarMenu"
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          {adminPresent ? <MenuItem onClick={() => {handleClose(); history.push("add")}}>Admin Console</MenuItem> : ''}
          {adminPresent ? <MenuItem onClick={() => {handleClose(); history.push("account")}}>My Account</MenuItem> : ''}
          {/* <MenuItem onClick={() => {handleClose(); history.push("termsofuse")}}>Terms of Use</MenuItem> */}
          <MenuItem onClick={() => {handleClose(); history.push("howtoqad")}}>How to QID</MenuItem>
          <MenuItem onClick={() => {handleClose(); history.push("contact")}}>Contact Us</MenuItem>
          <MenuItem onClick={() => {logoutClickHandler();}}>Logout</MenuItem>
        </Menu>
        </div> 
          // <Button onClick={logoutClickHandler} color="inherit">Logout</Button> 
          : ' '}
        </Toolbar>
      </AppBar>
      </React.Fragment>
}

export default NavBar;


const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: 40,
    height: '40px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    border: '1px solid #6b6b6b'
    
  },
  searchIcon: {
    width: theme.spacing(4),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create('width'),
    width: '100%'
  },
}));

export function SearchBar({onChange}) {
  const classes = useStyles();

  return (
    <div className={classes.search}>
    <div className={classes.searchIcon}>
        <SearchIcon />
    </div>
    <InputBase
        onChange={onChange}
        placeholder="Search…"
        classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
    />
    </div>
        
  );
}

