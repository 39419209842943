
import { call, select, takeEvery } from 'redux-saga/effects'


import {CREATE_ALL_USER_DATA, SAVE_ALL_USER_DATA } from '../actions/actions'
// import {REGISTER_PILOT, SAVE_USER} from '../actions/actions'
// import {CLAIM_ASSSIGNMENT} from '../actions/actions'


// import { getLoggedInUserUid, getLoggedInUserDocId } from './index'

import {saveUserData, createRegisterUserKey, createUserData,
    saveRegisterUserKeyInfo} from '../../firebase'

// import {saveExistingAssignment, 
// } from '../../firebase'



export const UsersSagas = [
    takeEvery(SAVE_ALL_USER_DATA, saveAllUserData),
    takeEvery(CREATE_ALL_USER_DATA, createAllUser)
]

function* createAllUser(action) {
    const companyName = yield select(state => state.user.userData.client);
    console.log(action)
    const registerDoc = yield call(createRegisterUserKey, {"email": action.userData.email, "client": companyName});
    const userData = {doc_id: registerDoc.id, 
        DELETED: false, 
        NEW: true,
        client: companyName, 
        date_modified: parseInt(new Date().valueOf()),  
        ...action.userData
    }
    yield call(createUserData, registerDoc.id, userData);
    if (action.callback) {action.callback()}

}

function* saveAllUserData(action) {
    let doc_id = action.userData.doc_id
    if (!doc_id) {
        doc_id = yield select(state => state.user.userData.doc_id);
    } else {
        action.userData["date_modified"] = parseInt(new Date().valueOf())
    }
    
    
    yield call(saveUserData, doc_id, action.userData)
    
    if (action.userData.DELETED) {
        yield call(saveRegisterUserKeyInfo, action.userData.doc_id, {"DELETED": true});
    }
    
    if (action.callback) {action.callback()}
}


//     let pilotData = action.pilotData
//     const userId = yield select(getLoggedInUserUid);
//     const userDocId = yield select(getLoggedInUserDocId);
    
//     const [imageLink1, imageLink2, imageLink3] = yield all([
//         call(saveImageData, userId, action.pilotData["upload1"] ? action.pilotData["upload1"][0] : null),
//         call(saveImageData, userId, action.pilotData["upload2"] ? action.pilotData["upload2"][0] : null),
//         call(saveImageData, userId, action.pilotData["upload3"] ? action.pilotData["upload3"][0] : null),
//     ]);

//     pilotData["upload1"] =  imageLink1
//     pilotData["upload2"] = imageLink2
//     pilotData["upload3"] = imageLink3
//     pilotData["DONE_FIRST_STEPS"] = true

//     yield call(saveCallsign, userId, pilotData["callsign"]);

//     yield put({type: SAVE_USER, userData: pilotData});
//     yield put({type: SAVING_TO_FIREBASE});
//     yield call(saveUserData, userDocId, pilotData);
//     yield put({type: SAVED_TO_FIREBASE});

    
//     // yield put({type: actions.SAVED_ALL_TO_FIREBASE});

//     window.location.href = "/#/"
// }


// function* claimAssignment(action) {
//     const pilot_callsign = yield select(state => state.user.userData.callsign);
//     const pilot_phone_number = yield select(state => state.user.userData.phone_num);
//     const pilot_uid = yield select(getLoggedInUserUid)

//     let assignmentData = {
//         pilot_callsign: pilot_callsign,
//         pilot_phone_number: pilot_phone_number,
//         pilot_uid: pilot_uid,
//         "STATUS": "ASSIGNED",
//     }

//     yield call(saveExistingAssignment, action.assignmentId, assignmentData);
// }