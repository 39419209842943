import React from 'react'

// import { connect } from 'react-redux'

import Container from '@material-ui/core/Container';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Paper from '@material-ui/core/Paper';

// import HomeIcon from '@material-ui/icons/Home';
// import Link from '@material-ui/core/Link';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavBar from './NavBar';

export default function TermsOfUse() {
    return <div>
        <NavBar />
        
        {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link style={{display: 'flex', marginTop: '15px'}} color="inherit" href="/" onClick={(event) => {event.preventDefault();history.push("/")}}>
            <HomeIcon style={{marginRight: '5px',width: 20,height: 20,}}/>
            Home
        </Link>
        </Breadcrumbs> */}

    <Container className="TermsOfUse" >

        <h1>Terms of Use</h1>

<h2>User’s Acknowledgment and Acceptance of Terms</h2>

VSol4U Technologies Private Limited (“YelloSKYE”, “Us” or “We”) provides the www.YelloSKYE.com site and various related sites, apps, systems, and services (collectively, the “Site”) to you, the user, subject to your compliance with all the terms, conditions, and notices contained or referenced herein (the “Terms of Use”), as well as any other written agreement between us and you. In addition, when using particular services or materials on this Site, users shall be subject to any posted rules applicable to such services or materials that may contain terms and conditions in addition to those in these Terms of Use. All such guidelines or rules are hereby incorporated by reference into these Terms of Use.
 <br /><br />
BY USING THIS SITE, YOU AGREE TO BE BOUND BY THESE TERMS OF USE. IF YOU DO NOT WISH TO BE BOUND BY THE THESE TERMS OF USE, PLEASE EXIT THE SITE NOW. YOUR REMEDY FOR DISSATISFACTION WITH THIS SITE, OR ANY PRODUCTS, SERVICES, CONTENT, OR OTHER INFORMATION AVAILABLE ON OR THROUGH THIS SITE, IS TO STOP USING THE SITE AND/OR THOSE PARTICULAR PRODUCTS OR SERVICES. YOUR AGREEMENT WITH US REGARDING COMPLIANCE WITH THESE TERMS OF USE BECOMES EFFECTIVE IMMEDIATELY UPON COMMENCEMENT OF YOUR USE OF THIS SITE.
<br /><br />

We expressly reserve the right to change these Terms of Use from time to time without notice to you. You acknowledge and agree that it is your responsibility to review this Site and these Terms of Use from time to time and to familiarize yourself with any modifications. Your continued use of this Site after such modifications will constitute acknowledgement of the modified Terms of Use and agreement to abide and be bound by the modified Terms of Use. However, for any material modifications to the Terms of Use or in the event that such modifications materially alter your rights or obligations hereunder, such amended Terms of Use will automatically be effective upon the earlier of (i) your continued use of this Site with actual knowledge of such modifications, or (ii) 30 days from publication of such modified Terms of Use on this Site. Notwithstanding the foregoing, the resolution of any dispute that arises between you and us will be governed by the Terms of Use in effect at the time such dispute arose.
<br /><br />

As used in these Terms of Use, references to our “Affiliates” include our owners, subsidiaries, affiliated companies, officers, directors, suppliers, partners, sponsors, and advertisers, and includes (without limitation) all parties involved in creating, producing, and/or delivering this Site and/or its contents.
<br /><br />

<h2>Our Services</h2>
We process data and images submitted by you in order to generate certain derived images, models, data, and analysis (herein sometimes “analysis,” or “data”). You will be notified when the results are available on our Site so that you can either view or download the results. We do not guarantee that data submitted by you will be stored for any length of time other than the time the same is subscribed for by you. You should never send us the only copy of any of your data or images. You should always send us a copy and keep the original / additional copy with you. The resulting analysis and data will be saved and made available for this period of 24 hours from the time you are notified. Beyond the period of your subscription, YelloSKYE reserves the right to delete the images and data entirely, or levy additional charges for storing them. 
<br /><br />

You will be responsible for the data and images submitted by you. You will be wholly and fully responsible for the quality, authenticity and appropriateness. You will ensure that data and images which are uploaded are not in bad taste or sexually offensive or would result in legal, moral  or ethical questions from your own employees, the public, the government or related or unrelated individuals, entities and / or regulators.
<br /><br />

YelloSKYE will not be held responsible for any data or images which do not meet the above criteria or are questioned by anybody who gets access to this data. If we find that the submitted images do not satisfy our  these conditions/quality criteria as determined by us whether or not posted on our Site, we reserve the right to reject them.
<br /><br />

To ensure the quality of the YelloSKYE experience, we have set-up our Terms of Use for our mutual benefit. If you violate these rules it will mean you’ve broken the Terms of Use and this may result in a termination of your account.
<br /><br />

We provide technology to help you capture, report and analyze your images and data. The decisions you make following receipt of that analysis and the way that you interpret and react to this analysis and data is your own. You acknowledge and agree that the quality and quantity of the decisions you make are not the responsibility of YelloSKYE. Without limiting the generality of the foregoing, you acknowledge and agree that, (i) we only present and in some cases analyze data, (ii) we do not recommend any course of action following delivery of that analysis, and (iii) we do not investigate any aspect of the data or images we receive, including to ensure accuracy of the images and data we provide. You acknowledge and agree that you are the one deciding to act or not act on any data, report or analysis provided by us. You acknowledge and agree that we do not have special expertise to make any recommendations following delivery of our analysis.
<br /><br />

The analysis we provide is non-transferable. You cannot sell or give away data or analysis you receive. Your YelloSKYE account is also non-transferable. That means if one person signs up, you cannot transfer ownership of that account to another person.
<br /><br />

<h2>Fees</h2>
When you sign up on our site you will provide us with a valid credit or debit card or other payment method acceptable to us and will keep such card active and updated at all times. You agree (i) that we may begin charging you as soon as you begin we create the capability for you to accessing a service for which there is a charge, and (ii) to pay for all services received through our Site, whether or not satisfied therewith. You acknowledge and agree that no charges by us are refundable to you. All pricing terms are confidential, and you agree not to disclose them to any third party. You agree to provide Us with complete and accurate billing and contact information, and to update such information within 10 days of any change.
<br /><br />

<h2>Guarantee, Warranty, and Limitations on Damages</h2>
There is no warranty on any of the information, analysis, or data we provide. What we will promise is that we’ll do our best to provide accurate data and images that you have provided to us. We expect you to validate and confirm the accuracy of your data for your own internal reporting, analysis and any other business or other requirement. If any error or inaccuracy is not validated or identified by yourselfs you agree that you will not hold us liable legally or morally. 
<br /><br />

You agree to indemnify, defend and hold us harmless from and against any and all claims, demands, actions, costs, liabilities, losses and damages of any kind (including attorneys’ fees) resulting from your registration with, use of, or activities on the Site, your breach of any provision of these Terms of Use and/or any intentional wrongdoing by you. In connection with any such defense, you shall employ counsel acceptable to us, and we shall be entitled to participate in such defense at our own cost and expense.
<br /><br />

WE PROVIDE THE SITE, ITS CONTENT AND OUR SERVICES “AS IS”, WHERE IS, AND WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY. WE SPECIFICALLY DISCLAIM THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. WE ALSO DO NOT GUARANTEE ANY INFORMATION OR ANALYSIS GIVEN YOU OR THE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THIS SITE OR OUR SERVICES, AND OPERATION OF THE SITE MAY BE INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE OF OUR CONTROL. YOU USE OUR SITE AT YOUR OWN RISK.
<br /><br />

You acknowledge that we are not and cannot be involved in transactions and communications between you and any third parties. In the event that you have a dispute with one or more third parties you release us and hereby hold us (and our agents, directors, officers, employees and content providers) harmless from any and all claims, demands, and damages (actual, consequential and punitive) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, whether or not suit is instituted, arising out of or in any way connected with the Site, or your use hereof or activities hereon (the “Released Claims”). You agree that we have no liability, whatsoever, for the unavailability of the Site caused by failure on or concerning the site or lack of maintenance or other work to keep the Site operable. We will also not have any liability for any loss of data or transactions resulting from delays or service interruptions caused by us, any third party acts or any other web host provider or the Internet infrastructure and network internal or external to the Site.
<br /><br />

IN NO EVENT SHALL AS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, OR FOR ANY LOSS OF PROFITS OR REVENUE, REGARDLESS OF WHETHER WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, AND IN NO EVENT SHALL OUR TOTAL CUMULATIVE LIABILITY UNDER THIS AGREEMENT EXCEED THE LOWER OF (i) Rs 1,000 AND (ii) THE AMOUNTS WHICH YOU HAVE ACTUALLY PAID TO US IN CONNECTION WITH YOUR USE OF THIS SITE. 
<br /><br />

The limitations of liability provided in these Terms of Use inure to the benefit of YelloSKYE, our affiliates and to all of our respective officers, directors, employees, attorneys and agents.
<br /><br />

<h2>Intellectual Property Ownership and Confidentiality</h2>
You own the images and data you provide to us. You acknowledge that we will not return those images and data to you. We agree that we will not transfer that images and data to any third party other than as set forth herein. You acknowledge that we have a license to use that images and data for the purposes of providing the services we offer. If we are compelled by law to deliver or produce your images or data, we will try to alert you of such as soon as reasonable. However, you acknowledge and agree that we own all intellectual property rights in all analysis, reports, and data produced by us. We hereby grant you a license to use that analysis, reports, and data produced by us on and through our system.
<br /><br />

To avoid doubt, you retain sole ownership of your confidential information and your intellectual property and, to the extent your confidential information or intellectual property is incorporated into the Work Product, it will be removed prior to any use or disclosure by YelloSKYE to a third party.
<br /><br />

As used in these Terms of Use, your confidential information means non-public information that you provide to YelloSKYE, including your personal information (i.e., your name, your social security number, your credit card information, and similar information), but does not include information that (1) becomes generally available to the public other than an unauthorized disclosure by YelloSKYE; (2) was or becomes available to YelloSKYE on a non-confidential basis prior to your disclosure of the information to YelloSKYE; (3) is independently developed by YelloSKYE without using your confidential information; or (4) information we are required to disclose by a warrant, subpoena or other request in an investigation or legal proceeding or where disclosure is necessary to protect YelloSKYE rights or property, or the rights or property of our other clients.
<br /><br />

As used in these Terms of Use, your intellectual property means patents, trademarks and copyrights owned by you and any insights, knowledge, and ideas provided by you in connection with creation of the Work Product.
<br /><br />

<h2>Cancellations</h2>
To cancel, just email us at: qid@yelloskye.com and ask us to cancel your account. We may cancel your account for any or no reason immediately upon notice to you.
<br /><br />

<h2>Governing Law</h2>
We are based in INDIA, and make no claims that we or our content is appropriate or may be legally accessed, used, or downloaded outside INDIA. Access to the Site may not be legal by certain persons or in certain countries. If you access our Site from outside INDIA, you do so at your own risk and are responsible for compliance with the laws of that jurisdiction.
By accessing, registering with, or using the Site, you agree that these Terms of Use and your use of the Site shall be governed in all respects by the internal substantive laws of INDIA, without regard to conflicts of laws rules and shall not be governed by the United Nations Convention on the International Sale of Goods or the laws of any other jurisdiction, state or country. You further submit to exclusive jurisdiction and venue in the courts located in INDIA and further agree that any cause of action, lawsuit, arbitration, or other dispute resolution procedure you may bring arising under or concerning this Site, or your use of or activities in connection with this Site shall be brought by you exclusively in INDIA having subject matter jurisdiction thereof.
 

<br /><br /><br /><br />
<h1>Terms and Conditions for Online Payments</h1>
<h2>Introduction</h2>

These terms and conditions apply to the User who uses the Online Services provided by Razory Pay (“the online payment service provider” or “the service”)  for any payment made to VSol4U Technologies Private Limited (“YelloSKYE”, “QID”, “Us” or “We”. Kindly read these terms and conditions carefully. By authorizing a payment to YelloSKYE through the online payment service ("the service"), it would be treated as a deemed acceptance to these terms and conditions. YelloSKYE reserves all the rights to amend these terms and conditions at any time without giving prior notice. It is the responsibility of the User to have read the terms and conditions before using the Service. 
<h2>Terms & Conditions</h2>
 
The following is a summary of the key terms of this service: <br />
<ul>
<li>Payment(s) through the online payment service provider may only be made with a Credit Card, Debit card or Net Banking or any other channel provided by the the online payment service provider. </li>
 
<li>Before using the service, it is recommended that the user shall make necessary enquiry about the charges or fees payable against the Credit/Debit card, net banking or any other channel used from Credit Card, Debit Card, net banking service provider or channel provideri.e. the respective Bank or channel provider. </li>
 
<li>The credit or debit card or netbanking or channel information supplied at the time of using the service is processed by the payment gateway of the online service provider and is not supplied to YelloSKYE. It is the sole responsibility of the User of the service to ensure that the information entered in the relevant fields are correct. It is recommended that you take and retain a copy of the transaction for record keeping purposes, which might assist in resolution of any disputes that may arise at a later date.</li>
 
<li>The user agrees, understands and confirms that his/ her personal data including without limitation details relating to debit card/ credit card/net banking and any other channel or detail transmitted over the Internet may be susceptible to misuse, hacking, theft and/ or fraud and that YelloSKYE or the online payment service provider(s) have no control over such matters </li>
 
<li>The service is provided using the online payment service provider through a secure website. However, neither the payment gateway service provider nor the YelloSKYE gives any assurance, that the information so provided online by a user is secured or may be read or intercepted by a third party. YelloSKYE does not accept or assume any liability in the event of such unauthorized interception, hacking or other unauthorized access to information provided by a user of the service. </li>
 
<li>YelloSKYE and/or the online payment service provider shall not be liable for any inaccuracy, error or delay in, or omission of (a) any data, information or message, or (b) the transmission or delivery of any such data, information or message; or (c) any loss or damage arising from or occasioned by any such inaccuracy, error, delay or omission, non-performance or interruption in any such data, information or message. Under no circumstances shall the YelloSKYE and/or the the online payment service provider, its employees, directors, and its third party agents involved in processing, delivering or managing the services, be liable for any direct, indirect, incidental, special or consequential damages, or any damages whatsoever, including punitive or exemplary arising out of or in any way connected with the provision of or any inadequacy or deficiency in the provision of the Services or resulting from unauthorized access or alteration of transmissions of data or arising from suspension or termination of the Service. </li>
 
<li>The user agrees that YelloSKYE or any of its employees will not be held liable by the user for any loss or damages arising from your use of, or reliance upon the information contained on the Website, or any failure to comply with these Terms and Conditions where such failure is due to circumstance beyond YelloSKYE reasonable control. </li>
 
<li>The user agrees that the debit/credit card, net banking or any other channel details provided for use of the service must be correct and accurate and that the user shall not use a Debit/ credit card, bank account or any other channel that is not lawfully owned by the user or the use of which is not authorized by the lawful owner thereof. The user further agrees and undertakes to provide correct and valid debit/credit card, net banking and any other channel details. </li>
<ul>
<li>The user will have to pay the subscription fees to YelloSKYE by using a debit/credit card or through online banking account or any other channel provided by the online payment service provider. The user warrants, agrees and confirms that when he/ she initiates a payment transaction and/or issues an online payment instruction and provides his/ her card / bank / other channel / etc. details: </li>
<li>The user is fully and lawfully entitled to use such card / bank / other channel / etc for such transactions; </li>
<li>The user is responsible to ensure that the card / bank / other channel / etc details provided by the user are accurate; </li>
<li>The user authorizes debit of the nominated card/ bank account / other channel / etc for the payment of fees selected by such user along with the applicable charges </li>
<li>The user is responsible to ensure that sufficient credit is available on the nominated card / bank / wallet / etc at the time of making the payment to permit payment of the dues payable or fees dues selected by the user. </li>
</ul>

<li>No warranty: No warranty, representation or guarantee, express or implied, is given by YelloSKYE or the online service provider in respect of the operation of the service. </li>
 
<li>Disclaimer and Limitation of liability: YelloSKYE does not accept liability for any damage, loss, cost (including legal costs), expenses, indirect losses or consequential damage of any kind which may be suffered or incurred by the User from the use of this service. </li>
 
<li>Refund/Cancellation Policy: Amounts once paid will not be refunded under any circumstances. </li>
 
<li>YelloSKYE and the online service provider reserve the right to decline the acceptance of an online payment if your account is in default for any reason. </li>
 
<li>YelloSKYEand the online service provider may also make additions/deletions/alteration to the services offered, at its sole discretion. YelloSKYE also  reserves the right to withdraw the service at any time at its discretion. </li>
</ul>
 



<br /><br /><br /><br />

<h1>Privacy Policy</h1>
 
<h2>Purpose</h2>
The purpose of this Privacy Policy is to describe how VSol4U Technologies Private Limited (“YelloSKYE”) collects, uses and shares information through our websites owned and controlled by us, including https://www.YelloSKYE.com (collectively referred to herein as the “Site”), and through any other medium you use to communicate with us. Please read this notice carefully to understand what we do. If you do not understand any aspects of our Privacy Policy, please feel free to Contact Us as described at the end of this Policy.
<br /><br />

Our Privacy Policy explains:<br />
<ul>
<li>Information We Collect And Why We Collect It</li>
<li>How We Use and Share Your Information</li>
<li>Access To Your Information And Choices</li>
<li>Security Of Your Information</li>
<li>Privacy Rights</li>
<li>Consent To Data Processing </li>
<li>Changes To Our Privacy Policy</li>
<li>Questions And How To Contact Us</li>
</ul>
<br />

<h2>Scope: Third Party Sites</h2>
This Privacy Policy applies to information we collect at and through the Site and to information you provide us through any other medium, including, for example, email, text messages, telephone, letters, or otherwise. Our Site also contains links to third party sites that are not owned or controlled by YelloSKYE. Please be aware that we are not responsible for the privacy practices of such other sites. We encourage you to be aware when you leave our Site and to read the privacy statements of each and every website that collects personal information.
<br /><br />

<h2>Terms Of Use</h2>
Please note that your use of our Site is also subject to our Terms of Service.
<br /><br />
 
 
 
 
<h2>Information We Collect, And Why We Collect It</h2>
 
<h2>Information You Provide To Us</h2>
Types of personal information we collect when you communicate with us include:
<br />
<ul>
<li>Name</li>
<li>Address</li>
<li>Company affiliation</li>
<li>Billing address</li>
<li>Email address</li>
<li>Telephone number</li>
<li>Credit or debit card number</li>
<li>Bank Account</li>
<li>Permanent Account Number - PAN </li>
<li>Goods & Service Tax Number - GST</li>
</ul><br />
We may also collect certain other information necessary to fulfill the service that you have requested, including, for example, loyalty program cards numbers, addresses to deliver items you ask us to order on your behalf, and your passport number and other travel document information. We advise you to not share any sensitive personal information that you do not feel comfortable disclosing to strangers, such as information that relates to medical facts, racial or ethnic origins, political or religious beliefs or sexuality.
<br /><br />

We may also collect and analyze information from the content of communications you have with us (including text messages and emails) using automated systems.
<br /><br />

<h2>Information Collected Through Analytics</h2>
We may use Google Analytics, a web analytics service provided by Google, Inc., or another similar service (“Analytics”) to collect certain information relating to your use of the Site. Analytics may use cookies to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Analytics on servers anywhere in the world. Analytics will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and Internet usage. Analytics may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Analytics’ behalf. Analytics will not associate your IP address with any other data held by it. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of our website. By using our website, you consent to the processing of data about you by Analytics in the manner and for the purposes set out above. For more information regarding Google Analytics please visit Google’s website, and pages that describe Google Analytics, such as www.google.com/analytics/learn/privacy.html.
<br /><br />

<h2>Information Collected Through Cookies And Similar Technologies</h2>
We and our service providers use cookies, web beacons, and other technologies to receive and store certain types of information whenever you interact with our website through your computer or mobile device. A cookie is a small file containing a string of characters that is sent to your computer when you visit a website. When you visit the website again, the cookie allows that site to recognize your browser. Cookies may store unique identifiers, user preferences and other information. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, some website features or services may not function properly without cookies. We use cookies to improve the quality of our service, including for storing user preferences, tracking user trends, and providing relevant advertising to you (see “Advertising” section below).
<br /><br />

<h2>No Information From Children Under Age 18</h2>
If you are under the age of 18, please do not attempt to register with us at this Site or provide any personal information about yourself to us. If we learn that we have collected personal information from a child under the age of 18, we will promptly delete that information. If you believe we have collected personal information from a child under the age of 18, please Contact Us. If you are over the age of 18, you may provide us with whatever information that is necessary to perform the services you request, and we will only use this information pursuant to this Privacy Policy.
<br /><br />

<h2>To Provide Products, Services, and Information.</h2>
As described above, we collect information from you so that we can provide products and services that you request, and provide information that you request from us. We use your personal information to:
<br />
<ul>
<li>Contact you about your service requests</li>
<li>Perform the services you requested</li>
<li>Process credit card / debit card / banik account / etc. transactions necessary for the services you requested</li>
<li>Personalize your experience</li>
<li>Improve our services</li>
</ul>
<br />
We may also use your information to send you information about products, services, and new offerings offered by us or by a Company partner through email or through text messages to your mobile phone. We may also contact you through email when new partners join the Company network and for new offers by Company partners.
<br /><br />
 
<h2>Third Party Access</h2>
We may provide information to third party service providers that help us fulfill services that you request from us. We may also use third parties to help host our Site, send out email updates about the Site, provide marketing, analytic, and advertising services for us, remove repetitive information from our user lists, and process payments. These service providers will have access to your personal information in order to provide these services, but when this occurs we implement reasonable contractual and technical protections to limit their use of that information to helping us provide the service.
<br /><br />

<h2>Advertising</h2>
We may use how you use the Site in order to show you ads for related or complimentary products and services or make suggestions that are relevant to your interests and individual needs. We may use cookies and other information to provide relevant interest-based advertising to you. Interest-based ads are ads presented to you based on your browsing behavior in order to provide you with ads more tailored to your interests. These interest-based ads may be presented to you while you are browsing our site or third-party sites not owned by Company.
We may belong to ad networks that may use your browsing history across participating websites to show you interest-based advertisements on those websites. You can opt-out of receiving interest-based ads from us by changing the settings on your browsers or by visiting [Google’s Ads Settings at www.google.com/settings/u/0/ads.] Please note that if you choose to opt out, you will continue to see ads on our Site, but they will not be based on how you browse and shop.
[Currently, our Site does not recognize if your browser sends a “do not track” signal or similar mechanism to indicate you do not wish to be tracked or receive interest-based ads. We allow third-party publishers across the web to collect information stored in cookies on your computer about your online activities when you leave this Site. To learn more about interest-based advertisements and your opt-out rights and options, visit the Digital Advertising Alliance and the Network Advertising Initiative websites (www.aboutads.info and www.networkadvertising.org). Please note that if you opt-out of receiving interest based advertising, you will still receive ads that will not necessarily be tailored to your interests.]
<br /><br />

<h2>Feedback</h2>
Your feedback and suggestions you submit, including feedback or suggestions submitted through online forms, social media sites, or through email, will be considered non-confidential and non-proprietary. You hereby assign to us any and all rights in and to, and grant us a perpetual, fully paid, global, royalty free, irrevocable right and license to use, all such ideas, suggestions, and feedback. We may use any of your feedback or suggestions to improve our services or for marketing purposes on our website or on any of our social media sites without any obligation to you.
<br /><br />

<h2>Statistical Analysis</h2>
We may use your information you provide to us through any medium (including emails you delegate us to process on your behalf), combined with other information we may obtain from other sources, to perform statistical, demographic, and marketing analyses of the users of the Company services, their service requests patterns, improve our understanding of our customers and potential customers, improve our service, and to improve customer relationships.
<br /><br />

<h2>Third Party Advertising And Marketing</h2>
From time to time with your consent, we may disclose information with Company partners who may offer you products and services of interest. You may opt-out of this sharing by Contacting Us. The information we may share with Company partners includes your name, address, email address, telephone number, and information about types of services you have requested. We require Company partners to agree to reasonable contractual and technical protections to limit their use of that information to their own advertising, and to not further share or distribute your information to non-partner third parties without your consent. If you have consented to our sharing your personal information with these third parties, you may subsequently opt-out by Contacting Us.
<br /><br />

<h2>Legal Proceedings</h2>
We will share personal information with third party companies, organizations or individuals outside of Company if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to:
<br />
<ul>
<li>Meet any applicable law, regulation, subpoena, legal process or enforceable governmental request</li>
<li>Enforce Our Terms of Use, including investigation of potential violations</li>
<li>Detect, prevent, or otherwise address fraud, illegal activity, network security or technical issues concerning our service</li>
<li>Protect against harm to the rights, property or safety of Company, our users (including you), customers or the public as required or permitted by law</li>
<li>Detect, prevent, or otherwise address risks to national security or other important public interests</li>
</ul>
<br />

<h2>Your Consent</h2>
In addition to the sharing described elsewhere in this Policy, we will share personal information with companies, organizations or individuals outside of Company when we have your express consent to do so.
<br /><br />

<h2>Transfer In The Event Of Sale Or Change Of Control</h2>
We do not sell, trade, or transfer your personally identifiable information to third parties except as follows: If the ownership of all or substantially all of our business changes or we otherwise transfer assets relating to our business or the Site to a third party, such as by merger, acquisition, bankruptcy proceeding or otherwise, we may share, transfer, or sell your personal information to the new owner. In such a case your information would remain subject to the promises made in the privacy policy in affect at that time. In addition, if the Company acquires another entity, the Company may share your information with our new corporate affiliate.
<br /><br />

<h2>Access To Your Information And Choices</h2>
If you have questions about personal information we have about you or need to update your information, you can Contact Us. While we are happy to provide you the categories of information we have about you, to protect the security of your information we cannot provide you with certain information you may have provided to us, including, for example, passwords, credit card information, and other account numbers.
<br /><br />

You may request that we remove any information we have about you by Contacting Us. You can opt-out of receiving marketing and promotional e-mails from Company by Contacting Us.
<br />
You may close your account as described in our Terms of Service. If you close your account, we will no longer use your online account information or share it with third parties. We may delete your information, including all stored or recorded information.. We may, however, retain a copy of the information for archival purposes, and to avoid identity theft or fraud.
<br /><br />

<h2>Security Of Your Information</h2>
We use industry standard physical, technical and administrative security measures and safeguards to protect the confidentiality and security of your personal information. However, since the Internet is not a 100% secure environment, we cannot guarantee, ensure, or warrant the security of any information you transmit to us. Although we use industry standard practices to protect your information stored on our servers, there is no guarantee that information, including sensitive personal information, may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. It is your responsibility to protect the security of your login information. Please note that e-mails and other communications you send to us through our Site are not encrypted, and we strongly advise you not to communicate any confidential information through these means.
<br /><br />

<h2>Consent To Data Processing </h2>
Our Site is operated and managed on servers located and operated within and outside INDIA. By using and accessing our Site, residents and citizens of INDIA and other countries and jurisdictions agree and consent to the transfer to and processing of personal information on servers wherever they are located, and that the protection of such information may be different than required under the laws of your residence or location.
<br /><br />

<h2>Changes To Our Privacy Policy</h2>
Our Privacy Policy may change from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of privacy policy changes). We will also keep prior versions of this Privacy Policy in an archive for your review.
<br /><br />

<h2>Questions And How To Contact Us</h2>
If you have any questions, concerns, complaints or suggestions regarding our Privacy Policy or otherwise need to contact us, please email us at qid@yelloskye.com.
<br /><br /><br /><br />

    </Container>

    </div>
}


