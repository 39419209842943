
import { put, select, delay, cancel, take, call, 
    fork, takeLatest, takeEvery } from 'redux-saga/effects'

import {getUserDataFromUID, getUserDataFromEmailNClient, saveUserData, doSignOut,
    initiateListeners, getTableLabels, saveFileOnStorage, setClientLogoLink} from '../../firebase'

import {initiateAllClientDataListener, saveClientData, createNewClientData, createRegisterUserKey, createUserData} from '../../firebase' // ADMIN ONLY

import {GOT_FIREBASE_DATA, GETTING_FIREBASE_DATA} from '../actions/actions'
import {AUTH_READY, SET_AUTH_SESSION, GOT_USER, SET_TABLE_LABELS} from '../actions/actions'


export const authSagas = [
    takeLatest(SET_AUTH_SESSION, getLoggedInUserData),
    takeEvery("LOGOUT", doSignOut),
    takeEvery('CREATE_NEW_CLIENT', createNewClientOnFire),
    takeLatest('UPLOAD_LOGO', uploadClientLogo)
    // takeEvery("AUTH_READY", initiateListeners)
]

function* getLoggedInUserData(action) {
    const authUser = action.user;
    const clientName = action.optionalClientName;


    yield put({type: GETTING_FIREBASE_DATA})
    try {
        const userDataDocs = yield call(getUserDataFromUID, authUser.uid)
        
        if (userDataDocs.docs.length) {
            const userData = userDataDocs.docs[0].data()
            yield put({ type: GOT_USER, userData: userData });
        } else {
            const newUserDataDocs = yield call(getUserDataFromEmailNClient, authUser.email, clientName)
            const userData = newUserDataDocs.docs[0].data()
            yield call(saveUserData, userData.doc_id, {uid: authUser.uid});
            yield put({ type: GOT_USER, userData: {...userData, uid: authUser.uid} });
        }

        if (authUser.uid === "8ykdiHM752SLOmn8JX6vte1n65o2") {
            yield call(initiateAllClientDataListener)
            // yield takeEvery('CREATE_NEW_CLIENT', createNewClientOnFire);
            yield takeEvery('SAVE_CLIENT_DATA', saveClientDataOnFire);
            // const task = yield fork(watchIncomingStorageData);
            // yield take(AUTH_READY)
            // yield cancel(task);

            yield put({type: AUTH_READY});
            window.location.href = "/#/"
            return
        } 

        const companyName = yield select(state => state.user.userData.client);
        const clientData = yield call(getTableLabels, companyName)

        // if (!clientData.get("LABELS_SAVED")) {
        yield takeEvery('SAVE_CLIENT_DATA', saveClientDataOnFire);
        // }

        // console.log("COMPANY NAME", companyName)
        yield put({ type: SET_TABLE_LABELS, clientData: clientData.data()});
        yield put({ type: GOT_FIREBASE_DATA });
        yield call(initiateListeners)
       

        console.log("WAITING TO LOG IN")
        const task = yield fork(watchIncomingData);
        yield take(AUTH_READY)
        yield cancel(task);
        window.location.href = "/#/"
        
      }
    catch(error) {
        alert("Error logging in, please contact YelloSKYE at qid@yelloskye.com")
        console.log("ERROR GETTING USER DATA", error);
        doSignOut()
      } 
}




function* handleLoginReady({ input }) {
    // debounce by 500ms
    yield delay(750)
    yield put({type: AUTH_READY});
    
}
  
function* watchIncomingData() {
    // will cancel current running handleLoginReady task
    yield takeLatest('INCOMING_DATA', handleLoginReady);
}

// function* watchIncomingStorageData() {
//     // will cancel current running handleLoginReady task
//     yield takeLatest('ADD_CLIENT_STORAGE_SIZE', handleLoginReady);
// }



function* createNewClientOnFire(action) {

    console.log(action);
    if (!action.callback) {console.log("NO CALLBACKK"); return}

    const data = action.data;
    data["max_issue_count"] = action.data.max_issue_count ? parseInt(action.data.max_issue_count) : 50
    data["expiry_date"] = action.data.expiry_date ? new Date(action.data.expiry_date) : new Date(new Date().getTime() + 2592000000)
    data["issue_count"] = 0
    data["PAID"] = false
    // data["NEW"] = true
    data["LABELS_SAVED"] = false
    data["date_created"] = new Date()
    
    console.log(data)
    yield call(createNewClientData, data)

    if (action.data.plan === "Basic") {
        const registerDoc = yield call(createRegisterUserKey, {"email": data.email, "client": data.client_name});
        const userData = { doc_id: registerDoc.id, 
            email: data.email, 
            type: "ADMIN", 
            NEW: true,
            NEW_CLIENT: true, // to send a different welcome email to user
            fname: data.fname,
            lname: data.lname,
            DELETED: false, 
            client: data.client_name, 
            vendors_assigned: "ALL",
            projects_assigned: ["ALL"],
            date_modified: parseInt(new Date().valueOf()) 
        }
    
        yield call(createUserData, registerDoc.id, userData);
    }
    
    action.callback()
}

function* saveClientDataOnFire(action) {
    const data = action.data;
    if (data["max_issue_count"]) {
        data["max_issue_count"] = parseInt(action.data.max_issue_count)
    }
    if (data["expiry_date"]) {
        data["expiry_date"] = new Date(action.data.expiry_date)
    }

    if (!data["client_name"]) {
        data["client_name"] =  yield select(state => state.user.userData.client);
    }
    
    delete data["size"];

    // console.log("SAVEDD CLIENT DATA ACTION", action, data);

    yield call(saveClientData, data)
    // console.log("SAVEDD CLIENT DATA ACTION", action);
    action.callback()
}



function* uploadClientLogo(action) {
    const clientName = yield select(state => state.user.userData.client);

    if (action.file) {
        const link = yield call(saveFileOnStorage, `Logos/${clientName}` , action.file)
        yield call(setClientLogoLink, clientName, link)
        yield put({ type: 'SET_LOCAL_CLIENT_LOGO', link: link });

    } else {
        yield call(setClientLogoLink, clientName, '')
        yield put({ type: 'SET_LOCAL_CLIENT_LOGO', link: null });
    }

    
    if (action.callback) { action.callback() }
   

}