
import { call, select, takeEvery } from 'redux-saga/effects'


import {SAVE_ALL_BUILDING_DATA, CREATE_ALL_BUILDING_DATA } from '../actions/actions'

import {saveBuildingData, createBuildingData
} from '../../firebase'




export const BuildingSagas = [
    takeEvery(SAVE_ALL_BUILDING_DATA, saveBuildingDataToFire),
    takeEvery(CREATE_ALL_BUILDING_DATA, createBuildingDataOnFire)
]

function* createBuildingDataOnFire(action) {
    const companyName = yield select(state => state.user.userData.client);
    
    const buildingData = { client: companyName,  ...action.buildingData}
    const buildingDoc = yield call(createBuildingData, buildingData);
    
    yield call(saveBuildingData, buildingDoc.id, {doc_id: buildingDoc.id, date_modified: parseInt(new Date().valueOf()) } );
    if (action.callback) {action.callback()}

}

function* saveBuildingDataToFire(action) {
    action.buildingData["date_modified"] = parseInt(new Date().valueOf())
    yield call(saveBuildingData, action.buildingData.doc_id, action.buildingData)
    if (action.callback) {action.callback()}
}


