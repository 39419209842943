import React from 'react';
import './App.css';
import 'react-image-lightbox/style.css';

import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import TopLoadingBar from './components/TopLoadingBar'
import { connect } from 'react-redux'

// const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

import LoadingBar from 'react-redux-loading-bar'

import TermsOfUse from './components/TermsOfUse'



// import {storage} from './firebase'
// import Mic from './components/Chat/Mic'

const Login = React.lazy(() => import('./components/Login'));
const New = React.lazy(() => import('./components/New'));
const Register = React.lazy(() => import('./components/Register'));
const ForgotPassword = React.lazy(() => import('./components/ForgotPassword'));
const Dashboard = React.lazy(() => import('./components/DashboardContainer'));



/*

PDF REPORT

*/


function App(props) {
  console.log("WELCOME TO QID")
  // getMetadata().then(function(metadata) {
  //   // Metadata now contains the metadata for 'images/forest.jpg'
  //   console.log("metadata", metadata);
  // }).catch(function(error) {
  //   // Uh-oh, an error occurred!
  // });

  let HomeRoute;
  if (props.haveAllData) {
    HomeRoute = (props) => <Dashboard {...props}/>
    console.log("HAVE ALL DATA")
  } else {
    console.log("REDIRECTING TO LOGIN :(...", window.location.hash.split("/")[1]);
    if (window.location.hash.split("/")[1] === "register") {
      HomeRoute = () => <Redirect to="/register" />
      window.location.href="/"+window.location.hash
    } else if (window.location.hash.split("/")[1] === "new") {
      HomeRoute = () => <Redirect to="/new" />
      window.location.href="/"+window.location.hash
    } else if (window.location.hash.split("/")[1] === "termsofuse") {
      HomeRoute = () => <Redirect to="/termsofuse" />
      window.location.href="/"+window.location.hash
    }
    else {
      HomeRoute = () => <Redirect to="/login" />
      window.location.href="/#/login"
    }
    
  }
  
  // const MakeNewAssignment = React.lazy(() => import('./components/new-assignment'));
  // return <Draw />
  //
  // return <Mic />

  return (<Router>
      <LoadingBar style={{backgroundColor: '#ecb93e'}} />
    <React.Suspense fallback={<TopLoadingBar />}>
      <Switch>    

        <Route exact name="TermsOfUse" path="/termsofuse" render={props => 
            <TermsOfUse /> } />

        <Route exact path="/new" name="New Client" render={props => 
          props.haveAllData ? HomeRoute(props) : <New {...props}/>}/>

        <Route path="/register" name="Register" render={props => 
          props.haveAllData ? HomeRoute(props) : <Register {...props}/>}/>

        <Route exact path="/login" name="Login" render={props => 
        props.haveAllData ? HomeRoute(props) : <Login {...props}/>}/>

        

        <Route exact path="/forgot-password" render={props => <ForgotPassword {...props}/>}/>
        
        
        <Route path="/" render={props => HomeRoute(props) } />
          
      </Switch>
    </React.Suspense>
    </Router>
  );
}


const mapStateToProps = (state, ownProps) => ({
  // ... computed data from state and optionally ownProps
  haveAllData: state.ui.authReady,
})

export default connect(
  mapStateToProps,
  null
)(App)

// export default App;
