import { all } from 'redux-saga/effects'



import { authSagas } from './auth';
import { firebaseDataSagas } from './firebase';
import { UsersSagas } from './Users'
import { ProjectSagas } from './Projects'
import { VendorSagas } from './Vendors'
import { BuildingSagas } from './Buildings'
import { FloorSagas } from './Floors'
import { DepartmentSagas } from './Departments'
import { SeveritySagas } from './Severitys'

import { IssuesSagas } from './Issues'



export const getLoggedInUserDocId = state => state.user.userData.doc_id
export const getLoggedInUserUid = state => state.user.authUser.uid



export default function* rootSaga() {
yield all([
    ...authSagas,
    ...firebaseDataSagas,
    ...UsersSagas,
    ...ProjectSagas,
    ...VendorSagas,
    ...BuildingSagas,
    ...FloorSagas,
    ...DepartmentSagas,
    ...SeveritySagas,
    ...IssuesSagas
])
}