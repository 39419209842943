
import { call, select, takeEvery } from 'redux-saga/effects'


import {SAVE_ALL_SEVERITY_DATA, CREATE_ALL_SEVERITY_DATA } from '../actions/actions'

import {saveSeverityData, createSeverityData
} from '../../firebase'




export const SeveritySagas = [
    takeEvery(SAVE_ALL_SEVERITY_DATA, saveSeverityDataToFire),
    takeEvery(CREATE_ALL_SEVERITY_DATA, createSeverityDataOnFire)
]

function* createSeverityDataOnFire(action) {
    const companyName = yield select(state => state.user.userData.client);
    
    const severityData = { client: companyName,  ...action.severityData}
    const severityDoc = yield call(createSeverityData, severityData);
    
    yield call(saveSeverityData, severityDoc.id, {doc_id: severityDoc.id, date_modified: parseInt(new Date().valueOf())} );
    if (action.callback) {action.callback()}

}

function* saveSeverityDataToFire(action) {
    action.severityData["date_modified"] = parseInt(new Date().valueOf())
    yield call(saveSeverityData, action.severityData.doc_id, action.severityData)
    if (action.callback) {action.callback()}
}


