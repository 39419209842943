
import { call, select, takeEvery } from 'redux-saga/effects'


import {SAVE_ALL_PROJECT_DATA, CREATE_ALL_PROJECT_DATA } from '../actions/actions'

import {saveProjectData, createProjectData
} from '../../firebase'




export const ProjectSagas = [
    takeEvery(SAVE_ALL_PROJECT_DATA, saveProjectDataToFire),
    takeEvery(CREATE_ALL_PROJECT_DATA, createProjectDataOnFire)
]

function* createProjectDataOnFire(action) {
    const companyName = yield select(state => state.user.userData.client);
    
    const projectData = { client: companyName,  ...action.projectData}
    const projectDoc = yield call(createProjectData, projectData);
    
    yield call(saveProjectData, projectDoc.id, {doc_id: projectDoc.id, date_modified: parseInt(new Date().valueOf())} );
    if (action.callback) {action.callback()}

}

function* saveProjectDataToFire(action) {
    action.projectData["date_modified"] = parseInt(new Date().valueOf())
    yield call(saveProjectData, action.projectData.doc_id, action.projectData)
    if (action.callback) {action.callback()}
}


