
import { put, select, takeEvery } from 'redux-saga/effects'


import {GOT_FIREBASE_DATA, GOT_ALL_FIREBASE_DATA} from '../actions/actions'
import {SAVED_TO_FIREBASE, SAVED_ALL_TO_FIREBASE} from '../actions/actions'
// import {AUTH_READY} from '../actions/actions'



export const firebaseDataSagas = [
    takeEvery(GOT_FIREBASE_DATA, checkIfGotAll),
    takeEvery(SAVED_TO_FIREBASE, checkIfAllSaved)
]

function* checkIfGotAll() {
    const numFirebaseLoadings = yield select(state => state.ui.isFirebaseLoading);
    if (numFirebaseLoadings === 0) {
        yield put({ type: GOT_ALL_FIREBASE_DATA });
        // yield put({ type: AUTH_READY });
        // window.location.href = "/#/"
    }
}

function* checkIfAllSaved() {
    const numFirebaseLoadings = yield select(state => state.ui.isFirebaseLoading);
    if (numFirebaseLoadings === 0) {
        yield put({ type: SAVED_ALL_TO_FIREBASE });
        window.location.href = "/#/dashboard"
    }
}
