
import { call, all, select, takeEvery, takeLatest } from 'redux-saga/effects'


import {CREATE_NEW_ISSUE, SAVE_ALL_ISSUE_DATA, SAVE_CHAT_MESSAGE, DELETE_CHAT_MESSAGE } from '../actions/actions'

import {saveIssuePicsData, createIssue, saveIssue, saveChatMessage, saveFileOnStorage, deleteChatMessage
} from '../../firebase'




export const IssuesSagas = [
    takeEvery(SAVE_ALL_ISSUE_DATA, saveIssueDataToFire),
    takeEvery(CREATE_NEW_ISSUE, createIssueDataOnFire),
    takeLatest(SAVE_CHAT_MESSAGE, saveChatMessageToFire),
    takeEvery(DELETE_CHAT_MESSAGE, deleteChatMessageOnFire)
]

function* createIssueDataOnFire(action) {
    const companyName = yield select(state => state.user.userData.client);
    const user_uid = yield select(state => state.user.authUser.uid);
    
    const { issueImages: issueImages, open_description: issueDescription, audioBlob: blob,  ...issueData} = action.issueData
    
    const [imageLink1, imageLink2, imageLink3] = yield all([
        call(saveIssuePicsData, `Issues/${companyName}/${action.issueData.project}/${Date.now().toString()}${Math.random().toString()}`, issueImages[0]),
        call(saveIssuePicsData, `Issues/${companyName}/${action.issueData.project}/${Date.now().toString()}${Math.random().toString()}`, issueImages[1]),
        call(saveIssuePicsData, `Issues/${companyName}/${action.issueData.project}/${Date.now().toString()}${Math.random().toString()}`, issueImages[2]),
    ]);
    
    issueData["client"] = companyName;
    issueData["opener_uid"] = user_uid;
    issueData["open_time"] = new Date(new Date().toDateString())
    issueData["DATE"] = new Date()
    issueData["STATUS"] = 'OPEN';
    issueData["open_image_links"] = [imageLink1, imageLink2, imageLink3]
    issueData["issueDescription"] = issueDescription

    const [issueDocRef, issueCount] = yield call(createIssue, issueData);

    const promises = [
        call(saveIssue, issueDocRef.id, {doc_id: issueDocRef.id}),
        call(saveChatMessage, {
            issue_id: issueDocRef.id,
            message: "OPENED",
            user_uid: user_uid
        }),
        call(saveChatMessage, {
            issue_id: issueDocRef.id,
            message: issueDescription,
            user_uid: user_uid
        })
    ]

    if (blob) {
        promises.push(call(saveChatMessageToFire, {
            issue_id: issueDocRef.id,
            message: blob,
            user_uid: user_uid,
            callback: function(){},
        }))
    }
    yield all(promises);

    if (action.callback) {action.callback(issueCount) }

}

function* saveIssueDataToFire(action) {
    const companyName = yield select(state => state.user.userData.client);
    const user_uid = yield select(state => state.user.authUser.uid);

    const { closeIssueImages: closeIssueImages, openIssueImages: openIssueImages,  ...issueData} = action.issueData
    

    if (openIssueImages && closeIssueImages) {
        const [openImageLink1, openImageLink2, openImageLink3, closeImageLink1, closeImageLink2, closeImageLink3] = yield all([
            call(saveIssuePicsData, `Issues/${companyName}/${action.issueData.project}/${Date.now().toString()}${Math.random().toString()}`, openIssueImages[0]),
            call(saveIssuePicsData, `Issues/${companyName}/${action.issueData.project}/${Date.now().toString()}${Math.random().toString()}`, openIssueImages[1]),
            call(saveIssuePicsData, `Issues/${companyName}/${action.issueData.project}/${Date.now().toString()}${Math.random().toString()}`, openIssueImages[2]),
            call(saveIssuePicsData, `Issues/${companyName}/${action.issueData.project}/${Date.now().toString()}${Math.random().toString()}`, closeIssueImages[0]),
            call(saveIssuePicsData, `Issues/${companyName}/${action.issueData.project}/${Date.now().toString()}${Math.random().toString()}`, closeIssueImages[1]),
            call(saveIssuePicsData, `Issues/${companyName}/${action.issueData.project}/${Date.now().toString()}${Math.random().toString()}`, closeIssueImages[2]),
        ]);

        issueData["open_image_links"] = [openImageLink1, openImageLink2, openImageLink3]
        issueData["close_image_links"] = [closeImageLink1, closeImageLink2, closeImageLink3]
    }


    if (issueData.STATUS === "OPEN") {
        issueData["opener_uid"] = user_uid;
    } else {
        issueData["closer_uid"] = user_uid;
    }

    // if (issueData["open_time"]) {
    //     issueData["open_time"] = new Date(new Date(issueData["open_time"]).toDateString())
    // }
    if (issueData["close_time"]) {
        issueData["close_time"] = new Date(new Date(issueData["close_time"]).toDateString())
        issueData["DATE"] = new Date()
    }

    

    // if (issueData["close_time"]) {
    //     issueData["DATE"] = issueData["close_time"]
    // } else {
    //     issueData["DATE"] = issueData["open_time"]
    // }
    // issueData["DATE"] = new Date(new Date().toDateString())

    yield call(saveIssue, issueData.doc_id, issueData)
    if (action.callback) {action.callback()}
}



function* saveChatMessageToFire(action) {
    const user_uid = yield select(state => state.user.authUser.uid);
    
    console.log("CALLL", action);

    if (action.callback) {
        const link = yield call(saveFileOnStorage, `Audio/${action.issue_id}/${user_uid}${Date.now().toString()}` , action.message)

        yield call(saveChatMessage, {
            issue_id: action.issue_id,
            message: "~AUDIO MESSAGE~",
            audioLink: link,
            user_uid: user_uid
        });
    } else {
        yield call(saveChatMessage, {
            issue_id: action.issue_id,
            message: action.message,
            user_uid: user_uid
        });
    }
    
   

    console.log("CALLLLED")

    if (action.callback) {action.callback()}

}


function* deleteChatMessageOnFire(action) {
    yield call(deleteChatMessage, action.chatID)
}