import {useEffect} from 'react'

import { connect } from 'react-redux'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

function TopLoadingBar({showLoading, hideLoading}) {

    useEffect(() => {
        console.log('mounted');
        showLoading()
        return () => hideLoading();
      }, [])

    return ''
}

const mapDispatchToProps = {
    showLoading, hideLoading
  }
  
  export default connect(
    null,
    mapDispatchToProps
  )(TopLoadingBar)
  