
import { call, select, takeEvery } from 'redux-saga/effects'


import {SAVE_ALL_DEPARTMENT_DATA, CREATE_ALL_DEPARTMENT_DATA } from '../actions/actions'

import {saveDepartmentData, createDepartmentData
} from '../../firebase'




export const DepartmentSagas = [
    takeEvery(SAVE_ALL_DEPARTMENT_DATA, saveDepartmentDataToFire),
    takeEvery(CREATE_ALL_DEPARTMENT_DATA, createDepartmentDataOnFire)
]

function* createDepartmentDataOnFire(action) {
    const companyName = yield select(state => state.user.userData.client);
    
    const departmentData = { client: companyName,  ...action.departmentData}
    const departmentDoc = yield call(createDepartmentData, departmentData);
    
    yield call(saveDepartmentData, departmentDoc.id, {doc_id: departmentDoc.id, date_modified: parseInt(new Date().valueOf())} );
    if (action.callback) {action.callback()}

}

function* saveDepartmentDataToFire(action) {
    action.departmentData["date_modified"] = parseInt(new Date().valueOf())
    yield call(saveDepartmentData, action.departmentData.doc_id, action.departmentData)
    if (action.callback) {action.callback()}
}


