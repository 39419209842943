
import { call, select, takeEvery } from 'redux-saga/effects'


import {SAVE_ALL_FLOOR_DATA, CREATE_ALL_FLOOR_DATA } from '../actions/actions'

import {saveFloorData, createFloorData, saveFileOnStorage
} from '../../firebase'




export const FloorSagas = [
    takeEvery(SAVE_ALL_FLOOR_DATA, saveFloorDataToFire),
    takeEvery(CREATE_ALL_FLOOR_DATA, createFloorDataOnFire)
]

function* createFloorDataOnFire(action) {
    const companyName = yield select(state => state.user.userData.client);
    const {floor_plan: floor_plan, ...floorDataWithoutPlan} = action.floorData
    floorDataWithoutPlan["client"] = companyName

    const floorDoc = yield call(createFloorData, floorDataWithoutPlan);
    
    let link = action.floorData.floor_plan ? action.floorData.floor_plan : ""
    if (link && link !== "LOADING" && link instanceof Blob) {
        link = yield call(saveFileOnStorage, `Floor Plans/${action.floorData.client}/${action.floorData.project}/${action.floorData.building}/${Date.now().toString()}` , action.floorData.floor_plan)
    }

    yield call(saveFloorData, floorDoc.id, {doc_id: floorDoc.id, floor_plan: link, date_modified: parseInt(new Date().valueOf())} );
    if (action.callback) {action.callback()}

}

function* saveFloorDataToFire(action) {
    let link = action.floorData.floor_plan
    if (link && link !== "LOADING" && link instanceof Blob) {
        link = yield call(saveFileOnStorage, `Floor Plans/${action.floorData.client}/${action.floorData.project}/${action.floorData.building}/${Date.now().toString()}` , action.floorData.floor_plan)
    }
    let floorData = {...action.floorData, floor_plan: link, date_modified: parseInt(new Date().valueOf())}
    
    yield call(saveFloorData, action.floorData.doc_id, floorData)
    if (action.callback) {action.callback()}
}


