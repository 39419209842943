/*
 * action types
 */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'

export const UPLOAD_LOGO = 'UPLOAD_LOGO'

export const SET_AUTH_SESSION = 'SET_AUTH_SESSION'
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE'
export const AUTH_READY = 'AUTH_READY'

export const FINISHED_SETUP = 'FINISHED_SETUP'

export const GOT_USER = 'GOT_USER'
export const SAVE_USER = 'SAVE_USER'


// triggers firebase event to modify Users
export const SAVE_ALL_USER_DATA = 'SAVE_ALL_USER_DATA'
export const CREATE_ALL_USER_DATA = 'CREATE_ALL_USER_DATA'

// triggers firebase event to modify Projects
export const SAVE_ALL_PROJECT_DATA = 'SAVE_ALL_PROJECT_DATA'
export const CREATE_ALL_PROJECT_DATA = 'CREATE_ALL_PROJECT_DATA'

// triggers firebase event to modify Vendors
export const SAVE_ALL_VENDOR_DATA = 'SAVE_ALL_VENDOR_DATA'
export const CREATE_ALL_VENDOR_DATA = 'CREATE_ALL_VENDOR_DATA'

// triggers firebase event to modify Buildings
export const SAVE_ALL_BUILDING_DATA = 'SAVE_ALL_BUILDING_DATA'
export const CREATE_ALL_BUILDING_DATA = 'CREATE_ALL_BUILDING_DATA'

// triggers firebase event to modify Floors
export const SAVE_ALL_FLOOR_DATA = 'SAVE_ALL_FLOOR_DATA'
export const CREATE_ALL_FLOOR_DATA = 'CREATE_ALL_FLOOR_DATA'

// triggers firebase event to modify Departments
export const SAVE_ALL_DEPARTMENT_DATA = 'SAVE_ALL_DEPARTMENT_DATA'
export const CREATE_ALL_DEPARTMENT_DATA = 'CREATE_ALL_DEPARTMENT_DATA'

// triggers firebase event to modify Severitys
export const SAVE_ALL_SEVERITY_DATA = 'SAVE_ALL_SEVERITY_DATA'
export const CREATE_ALL_SEVERITY_DATA = 'CREATE_ALL_SEVERITY_DATA'



// triggers firebase event to modify Issues
export const CREATE_NEW_ISSUE = 'CREATE_NEW_ISSUE';
export const SAVE_ALL_ISSUE_DATA = 'SAVE_ALL_ISSUE_DATA'
export const SAVE_CHAT_MESSAGE = 'SAVE_CHAT_MESSAGE'
export const DELETE_CHAT_MESSAGE = 'DELETE_CHAT_MESSAGE'

// App specific data
export const SET_MIN_ISSUE_DATE_SEARCH = 'SET_MIN_ISSUE_DATE_SEARCH';
export const SET_MAX_ISSUE_DATE_SEARCH = 'SET_MAX_ISSUE_DATE_SEARCH';
export const SET_TYPE_ISSUE_DATE_SEARCH = 'SET_TYPE_ISSUE_DATE_SEARCH'

export const CLEAR_ISSUE_DATE_SEARCH = 'CLEAR_ISSUE_DATE_SEARCH'

export const SET_TABLE_LABELS = 'SET_TABLE_LABELS';
export const SAVED_TABLE_LABELS = 'SAVED_TABLE_LABELS';



// firebase listener calls action to save changes locally
export const SAVE_LOCAL_USER = 'SAVE_LOCAL_USER'
export const REMOVE_LOCAL_USER = 'REMOVE_LOCAL_USER'

// firebase listener calls action to save changes locally
export const SAVE_LOCAL_PROJECT = 'SAVE_LOCAL_PROJECT'
// export const REMOVE_LOCAL_PROJECT = 'REMOVE_LOCAL_PROJECT'

// firebase listener calls action to save changes locally
export const SAVE_LOCAL_VENDOR = 'SAVE_LOCAL_VENDOR'
// export const REMOVE_LOCAL_VENDOR = 'REMOVE_LOCAL_VENDOR'

// firebase listener calls action to save changes locally
export const SAVE_LOCAL_DEPARTMENT = 'SAVE_LOCAL_DEPARTMENT'

// firebase listener calls action to save changes locally
export const SAVE_LOCAL_SEVERITY = 'SAVE_LOCAL_SEVERITY'

// firebase listener calls action to save changes locally
export const SAVE_LOCAL_BUILDING = 'SAVE_LOCAL_BUILDING'
// export const REMOVE_LOCAL_BUILDING = 'REMOVE_LOCAL_BUILDING'

// firebase listener calls action to save changes locally
export const SAVE_LOCAL_FLOOR = 'SAVE_LOCAL_FLOOR'
// export const REMOVE_LOCAL_FLOOR = 'REMOVE_LOCAL_FLOOR'

// firebase listener calls action to save changes locally
export const SAVE_LOCAL_ISSUE = 'SAVE_LOCAL_ISSUE'
// export const REMOVE_LOCAL_ISSUE = 'REMOVE_LOCAL_ISSUE'





// uses isFirebaseLoading counter
export const SAVING_TO_FIREBASE = 'SAVING_TO_FIREBASE'
export const SAVED_TO_FIREBASE = 'SAVED_TO_FIREBASE'
export const SAVED_ALL_TO_FIREBASE = 'SAVED_ALL_TO_FIREBASE'
// uses isFirebaseLoading counter
export const GETTING_FIREBASE_DATA = 'GETTING_FIREBASE_DATA'
export const GOT_FIREBASE_DATA = 'GOT_FIREBASE_DATA'
export const GOT_ALL_FIREBASE_DATA = 'GOT_ALL_FIREBASE_DATA'


export const ERROR_FROM_FIREBASE = 'ERROR_FROM_FIREBASE'









