import { applyMiddleware, createStore } from 'redux'

import rootReducer from './reducers'

import { composeWithDevTools } from 'redux-devtools-extension'

import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas/index'



// const logger = store => next => action => {
//     console.group(action.type)
//     console.info('dispatching', action)
//     let result = next(action)
//     console.log('next state', store.getState())
//     console.groupEnd()
//     return result
//   }

// const round = number => Math.round(number * 100) / 100

// const monitorReducerEnhancer = createStore => (
//   reducer,
//   initialState,
//   enhancer
// ) => {
//   const monitoredReducer = (state, action) => {
//     const start = performance.now()
//     const newState = reducer(state, action)
//     const end = performance.now()
//     const diff = round(end - start)
//     console.log('reducer process time:', diff)
//     return newState
//   }
//   return createStore(monitoredReducer, initialState, enhancer)
// }


const sagaMiddleware = createSagaMiddleware()


export default function configureStore(preloadedState) {
    const middlewares = [sagaMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)
    const enhancers = [middlewareEnhancer]
    const composedEnhancers = composeWithDevTools(...enhancers)
    const store = createStore(rootReducer, preloadedState, composedEnhancers)

    sagaMiddleware.run(rootSaga);


    // if (process.env.NODE_ENV !== 'production' && module.hot) {
    //     module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    // }

    return store
}