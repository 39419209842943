import firebase from "firebase/app";
import {store} from './index';


import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);


export default firebase;


export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();


export const createPaymentOrder = firebase.functions().httpsCallable('createPaymentOrder');


  

// *** Auth API ***
export const doCreateUserWithEmailAndPassword = (email, password) =>
auth.createUserWithEmailAndPassword(email, password);

export const doSignInWithEmailAndPassword = (email, password) =>
auth.signInWithEmailAndPassword(email, password);

export const doSignOut = () => {
  auth.signOut();
  // window.location.href="https://www.yelloskye.com/qid"
}

export const doSendPasswordResetEmail = (email) =>
auth.sendPasswordResetEmail(email);










export const checkRegistrationKey = (key) =>
  db.collection("register_ids").doc(key).get()



// ADMIN ONLY
export const initiateAllClientDataListener = () =>
  db.collection("Client")
  .onSnapshot(function(querySnapshot) {
    // var cities = [];
    querySnapshot.forEach(function(doc) {
        const clientData = doc.data()
        clientData["client_name"] = doc.id
        clientData["expiry_date"] = clientData["expiry_date"] ? new Date(clientData["expiry_date"]["seconds"]*1000) : ""
        clientData["date_created"] = clientData["date_created"] ? new Date(clientData["date_created"]["seconds"]*1000) : ""
        store.dispatch({type: "SAVE_LOCAL_CLIENT", doc_id: doc.id, data: clientData})
        // if (!doc.metadata.hasPendingWrites) { 
        //   console.log("CALC FOR", doc.id);
        //   calculateStorageForClient(doc.id) 
        // }
        
    });
})


export const createNewClientData = (data) => {
  db.collection("Client").doc(data.client_name).set(data)
}
export const saveClientData = (data) => {
  db.collection("Client").doc(data.client_name).update(data)
}

export const calculateStorageForClient = (client) => {
  storage.ref().child("Issues").child(client).listAll().then(function(result) {
    // console.log(result);
    result.prefixes.forEach(function(issueRef) {
      // console.log("issueRef.fullPath", issueRef.fullPath)
      storage.ref().child(issueRef.fullPath).listAll().then(function(pics) {
        // console.log("pics", pics)
        pics.items.forEach(function(imageRef) {
          // console.log(imageRef);
          storage.ref().child(imageRef.fullPath).getMetadata().then(function (metadata) {
            // console.log(metadata)
            // const size = metadata.size / (1000*1000)
            store.dispatch({type: "ADD_CLIENT_STORAGE_SIZE", "client_name": client, "size": metadata.size})
          });
        });
      });
    });
  })
}



// For NEW CLIENT SIGNUP
export const getDocByClientName = (clientName) => {
  return db.collection("Client")//.doc(clientName)
  .where("client_name", '==',clientName)
  .where("PAID",'==', true)
  .limit(1)
  .get()
}
export const getDocsWithEmailToCheckExistance = (email) => {
  return db.collection("Users")
  .where("email", "==", email)
  .where("DELETED", "==", false)
  .limit(1).get()
}

export const updateClientOrderIdInfo = (clientName, order_id) => {
  return db.collection("Client").doc(clientName).update({"orderId": order_id})
}


// LOGO Upload
export const setClientLogoLink = (clientName, link) => {
  return db.collection("Client").doc(clientName).update({"logoLink": link})
}





// For CONTACT US
export const saveContactUsMessage = (data) => {
  return db.collection("ContactUs").add(data)
}







// For USER AUTH  
export const getUserDataFromUID = (uid) => 
  firebase.firestore().collection("Users").where("uid", "==", uid).limit(1).get()

export const getUserDataFromEmailNClient = (email, client) => 
  firebase.firestore().collection("Users")
  .where("email", "==", email)
  .where("client", "==", client)
  .where("DELETED", "==", false)
  .limit(1).get()

export const getTableLabels = (client) =>
  db.collection("Client").doc(client).get()



// Users Collection
export const saveUserData = (doc_id, userData) =>
  firebase.firestore().collection("Users").doc(doc_id).update(userData)

export const createRegisterUserKey = (data) =>
  db.collection("register_ids").add(data)

export const saveRegisterUserKeyInfo = (doc_id, data) =>
  db.collection("register_ids").doc(doc_id).update(data)

export const createUserData = (doc_id, userData) =>
  db.collection("Users").doc(doc_id).set(userData)


  


// Projects Collection
export const saveProjectData = (doc_id, projectData) =>
  db.collection("Projects").doc(doc_id).update(projectData)

export const createProjectData = (projectData) =>
  db.collection("Projects").add(projectData)



// Vendors Collection
export const saveVendorData = (doc_id, vendorData) =>
  db.collection("Vendors").doc(doc_id).update(vendorData)

export const createVendorData = (vendorData) =>
  db.collection("Vendors").add(vendorData)


  // Departments Collection
export const saveDepartmentData = (doc_id, departmentData) =>
db.collection("Departments").doc(doc_id).update(departmentData)

export const createDepartmentData = (departmentData) =>
db.collection("Departments").add(departmentData)



// Severitys Collection
export const saveSeverityData = (doc_id, severityData) =>
  db.collection("Severitys").doc(doc_id).update(severityData)

export const createSeverityData = (severityData) =>
  db.collection("Severitys").add(severityData)



// Buildings Collection
export const saveBuildingData = (doc_id, buildingData) =>
  db.collection("Buildings").doc(doc_id).update(buildingData)

export const createBuildingData = (buildingData) =>
  db.collection("Buildings").add(buildingData)



// Floors Collection
export const saveFloorData = (doc_id, floorData) =>
  db.collection("Floors").doc(doc_id).update(floorData)

export const createFloorData = (floorData) =>
  db.collection("Floors").add(floorData)










export const saveFileOnStorage = (fileName, file) => {
  if (!file) {return ""}
  return storage.ref().child(fileName).put(file)
  .then(function(snapshot){
      return snapshot.ref.getDownloadURL().then(function(downloadURL){
          return downloadURL
      })
  })
  
}











// Issues Collection
export const createIssue = (issueData) => 
  db.runTransaction(function(transaction) {
    const clientDocRef = db.collection("Client").doc(issueData["client"]);
    return transaction.get(clientDocRef).then(function(clientDoc) {
        if (!clientDoc.exists) {
            throw "Document does not exist!";
        }

        const newIssuesCount = clientDoc.data().issue_count + 1;
        transaction.update(clientDocRef, { issue_count: newIssuesCount });
        return newIssuesCount;
        
    });
}).then(function(newIssuesCount) {
    issueData["issue_count"] = newIssuesCount
    return db.collection("Issues").add(issueData).then(function(docRef) {
      return [docRef, newIssuesCount]
    });
    // console.log("Issue Count increased to ", newIssuesCount, issueData);
});


export const saveChatMessage = (messageData) => {
  if (!messageData.message) {return}
  messageData["timestamp"] = firebase.firestore.FieldValue.serverTimestamp()
  // console.log("SAVING MESSAGE", messageData);
  return db.collection("Chat").add(messageData)
}

export const deleteChatMessage = (chatID) => {
  if (!chatID) {return}
  // messageData["timestamp"] = firebase.firestore.FieldValue.serverTimestamp()
  // console.log("SAVING MESSAGE", messageData);
  return db.collection("Chat").doc(chatID).update({"DELETED": true});
}

export const getChatMessages = (issue_id, callback) => {
  // console.log("FOR ISSUE ID MESSAGES", issue_id);
  return db.collection("Chat").where("issue_id", "==", issue_id)
  .orderBy("timestamp", "asc")
  .onSnapshot(function(snapshot) {
    // console.log("SNAPSHOT", snapshot.docs);
    callback(snapshot);
  });
}

export const uploadAudioMessage = (fileName, file) => {
  if (!file) {return ""}
  return storage.ref().child(fileName).put(file)
  .then(function(snapshot){
      return snapshot.ref.getDownloadURL().then(function(downloadURL){
          return downloadURL
      })
  })
  
}




  
  

export const saveIssue = (doc_id, issueData) =>
  db.collection("Issues").doc(doc_id).update(issueData)

export const saveIssuePicsData = (fileName, file) => {

    if (!file) {return ""}
    if (typeof (file) === "string" ) {
      if (file.slice(0,10) !== "data:image") {return file}
    } 
    // if (!file.type) {return file}
    console.log("FILE UPLAOD", fileName, file)

    if (typeof (file) === "string" ) {
      return storage.ref().child(fileName).putString(file, 'data_url')
      .then(function(snapshot){
          return snapshot.ref.getDownloadURL().then(function(downloadURL){
              return downloadURL
          })
      })
    } else {
      return storage.ref().child(fileName).put(file)
      .then(function(snapshot){
          return snapshot.ref.getDownloadURL().then(function(downloadURL){
              return downloadURL
          })
      })
    }

    



}




  // *** Listeners ***

  let usersListener = () => {}; 
  let projectsListener = () => {}; 
  let vendorsListener = () => {}; 
  let buildingsListener = () => {}; 
  let floorsListener = () => {}; 
  let severitysListener = () => {}; 
  let departmentsListener = () => {}; 

  let issuesListener = () => {}; 

export const initiateListeners = () => {
  initiateIssueListener();

    usersListener();

    usersListener = db.collection("Users")
    .where("client", "==", store.getState().user.userData.client)
    // .where("DELETED", "==", false)
    .onSnapshot(function(snapshot) {
      
      store.dispatch({type: "INCOMING_DATA"});

      snapshot.docChanges().forEach(function(change) {

          if (store.getState().user.userData.doc_id === change.doc.id) {
            store.dispatch({type: "SAVE_USER", userData: change.doc.data()})
          }

          if (change.type === "added") {
              store.dispatch({type: "SAVE_LOCAL_USER", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
              store.dispatch({type: "SAVE_LOCAL_USER", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("Modified city: ", change.doc.data());
          }
          if (change.type === "removed") {
              store.dispatch({type: "REMOVE_LOCAL_USER", doc_id: change.doc.id});
              // console.log("Removed city: ", change.doc.data());
          }
      });
    });



    projectsListener();

    projectsListener = db.collection("Projects")
    .where("client", "==", store.getState().user.userData.client)
    // .where("DELETED", "==", false)
    .onSnapshot(function(snapshot) {

      store.dispatch({type: "INCOMING_DATA"});

      snapshot.docChanges().forEach(function(change) {
          if (change.type === "added") {
              store.dispatch({type: "SAVE_LOCAL_PROJECT", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
              store.dispatch({type: "SAVE_LOCAL_PROJECT", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("Modified city: ", change.doc.data());
          }
          // if (change.type === "removed") {
          //     store.dispatch({type: "REMOVE_LOCAL_PROJECT", doc_id: change.doc.id});
          //     // console.log("Removed city: ", change.doc.data());
          // }
      });
    });




    vendorsListener();

    vendorsListener = db.collection("Vendors")
    .where("client", "==", store.getState().user.userData.client)
    // .where("DELETED", "==", false)
    .onSnapshot(function(snapshot) {

      store.dispatch({type: "INCOMING_DATA"});

      snapshot.docChanges().forEach(function(change) {
          if (change.type === "added") {
              store.dispatch({type: "SAVE_LOCAL_VENDOR", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
              store.dispatch({type: "SAVE_LOCAL_VENDOR", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("Modified city: ", change.doc.data());
          }
          // if (change.type === "removed") {
          //     store.dispatch({type: "REMOVE_LOCAL_VENDOR", doc_id: change.doc.id});
          //     // console.log("Removed city: ", change.doc.data());
          // }
      });
    });


    buildingsListener();

    buildingsListener = db.collection("Buildings")
    .where("client", "==", store.getState().user.userData.client)
    // .where("DELETED", "==", false)
    .onSnapshot(function(snapshot) {

      store.dispatch({type: "INCOMING_DATA"});

      snapshot.docChanges().forEach(function(change) {
          if (change.type === "added") {
              store.dispatch({type: "SAVE_LOCAL_BUILDING", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
              store.dispatch({type: "SAVE_LOCAL_BUILDING", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("Modified city: ", change.doc.data());
          }
          // if (change.type === "removed") {
          //     store.dispatch({type: "REMOVE_LOCAL_BUILDING", doc_id: change.doc.id});
          //     // console.log("Removed city: ", change.doc.data());
          // }
      });
    });




    floorsListener();

    floorsListener = db.collection("Floors")
    .where("client", "==", store.getState().user.userData.client)
    // .where("DELETED", "==", false)
    .onSnapshot(function(snapshot) {
      snapshot.docChanges().forEach(function(change) {

        store.dispatch({type: "INCOMING_DATA"});

          if (change.type === "added") {
              store.dispatch({type: "SAVE_LOCAL_FLOOR", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
              store.dispatch({type: "SAVE_LOCAL_FLOOR", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("Modified city: ", change.doc.data());
          }
          // if (change.type === "removed") {
          //     store.dispatch({type: "REMOVE_LOCAL_FLOOR", doc_id: change.doc.id});
          //     // console.log("Removed city: ", change.doc.data());
          // }
      });
    });

    severitysListener();

    severitysListener = db.collection("Severitys")
    .where("client", "==", store.getState().user.userData.client)
    // .where("DELETED", "==", false)
    .onSnapshot(function(snapshot) {

      store.dispatch({type: "INCOMING_DATA"});

      snapshot.docChanges().forEach(function(change) {
          if (change.type === "added") {
              store.dispatch({type: "SAVE_LOCAL_SEVERITY", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
              store.dispatch({type: "SAVE_LOCAL_SEVERITY", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("Modified city: ", change.doc.data());
          }
          // if (change.type === "removed") {
          //     store.dispatch({type: "REMOVE_LOCAL_VENDOR", doc_id: change.doc.id});
          //     // console.log("Removed city: ", change.doc.data());
          // }
      });
    });


    departmentsListener();

    departmentsListener = db.collection("Departments")
    .where("client", "==", store.getState().user.userData.client)
    // .where("DELETED", "==", false)
    .onSnapshot(function(snapshot) {

      store.dispatch({type: "INCOMING_DATA"});

      snapshot.docChanges().forEach(function(change) {
          if (change.type === "added") {
              store.dispatch({type: "SAVE_LOCAL_DEPARTMENT", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
              store.dispatch({type: "SAVE_LOCAL_DEPARTMENT", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("Modified city: ", change.doc.data());
          }
          // if (change.type === "removed") {
          //     store.dispatch({type: "REMOVE_LOCAL_VENDOR", doc_id: change.doc.id});
          //     // console.log("Removed city: ", change.doc.data());
          // }
      });
    });


    

    
    
  }
  


function initiateIssueListener() {
  issuesListener()

  issuesListener = db.collection("Issues")
    .where("client", "==", store.getState().user.userData.client);
    if (store.getState().app.minDate) {
      issuesListener = issuesListener.where("DATE", '>=', store.getState().app.minDate)
    }
    if (store.getState().app.maxDate) {
      issuesListener = issuesListener.where("DATE", '<=', store.getState().app.minDate)
    }
    if (store.getState().user.userData.vendors_assigned && store.getState().user.userData.vendors_assigned !== 'ALL') {
      issuesListener = issuesListener.where("vendor", '==', store.getState().user.userData.vendors_assigned)
    } 
    if (store.getState().user.userData.projects_assigned && store.getState().user.userData.projects_assigned[0] !== 'ALL') {
      issuesListener = issuesListener.where("project", 'in', store.getState().user.userData.projects_assigned)
    }

    issuesListener = issuesListener.onSnapshot(function(snapshot) {
      snapshot.docChanges().forEach(function(change) {

        store.dispatch({type: "INCOMING_DATA"});
        store.dispatch({type: "GOT_INIT_ISSUE"});

          if (change.type === "added") {
              store.dispatch({type: "SAVE_LOCAL_ISSUE", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
              store.dispatch({type: "SAVE_LOCAL_ISSUE", doc_id: change.doc.id, data: change.doc.data()})
              // console.log("Modified city: ", change.doc.data());
          }
          // if (change.type === "removed") {
          //     store.dispatch({type: "REMOVE_LOCAL_FLOOR", doc_id: change.doc.id});
          //     // console.log("Removed city: ", change.doc.data());
          // }
      });
    });
}






  // auth.onAuthStateChanged(function(user) {
  //     if (!user) {
          
  //       store.dispatch({type: "SET_AUTH_SESSION", user: user});

          
  //     } else {
          
  //         store.dispatch({type: "LOGOUT"});
  //       // User is signed out.
  //       // ...
  //     }
  //   });