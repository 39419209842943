
import { call, select, takeEvery } from 'redux-saga/effects'


import {SAVE_ALL_VENDOR_DATA, CREATE_ALL_VENDOR_DATA } from '../actions/actions'

import {saveVendorData, createVendorData
} from '../../firebase'




export const VendorSagas = [
    takeEvery(SAVE_ALL_VENDOR_DATA, saveVendorDataToFire),
    takeEvery(CREATE_ALL_VENDOR_DATA, createVendorDataOnFire)
]

function* createVendorDataOnFire(action) {
    const companyName = yield select(state => state.user.userData.client);
    
    const vendorData = { client: companyName,  ...action.vendorData}
    const vendorDoc = yield call(createVendorData, vendorData);
    
    yield call(saveVendorData, vendorDoc.id, {doc_id: vendorDoc.id, date_modified: parseInt(new Date().valueOf())} );
    if (action.callback) {action.callback()}

}

function* saveVendorDataToFire(action) {
    action.vendorData["date_modified"] = parseInt(new Date().valueOf())
    yield call(saveVendorData, action.vendorData.doc_id, action.vendorData)
    if (action.callback) {action.callback()}
}


