import { combineReducers } from 'redux';

import * as actions from '../actions/actions';
import { loadingBarReducer } from 'react-redux-loading-bar'

const initialUiState = {
    "isFirebaseLoading": 0,
    "firebaseGotAllData": true,
    "firebaseSavedAllData": true,
    "firebaseErrorStatus": false,
    "firebaseError": null,
    "authReady": false,
    "gotIssueCount": 0
}

function ui(state = initialUiState, action) {
    switch (action.type) {
        case actions.SET_AUTH_SESSION:
            return {...state, firebaseGotAllData: false}
        case actions.AUTH_READY:
            return {...state, authReady: true}
        case "GOT_INIT_ISSUE":
            const newCount = state.gotIssueCount + 1
            return {...state, gotIssueCount: newCount}



        case actions.GETTING_FIREBASE_DATA:
            return {...state, isFirebaseLoading: state.isFirebaseLoading+1, firebaseGotAllData: false}
        case actions.GOT_FIREBASE_DATA:
            return {...state, isFirebaseLoading: state.isFirebaseLoading-1}
        case actions.GOT_ALL_FIREBASE_DATA:
            return {...state, firebaseGotAllData: true}

        case actions.SAVING_TO_FIREBASE:
            return {...state, isFirebaseLoading: state.isFirebaseLoading+1, firebaseSavedAllData: false}
        case actions.SAVED_TO_FIREBASE:
            return {...state, isFirebaseLoading: state.isFirebaseLoading-1}
        case actions.SAVED_ALL_TO_FIREBASE:
            return {...state, firebaseSavedAllData: true}

        
        
        

        
        case actions.ERROR_FROM_FIREBASE:
            return {...state, isFirebaseLoading: state.isFirebaseLoading-1, firebaseErrorStatus: true, firebaseError: action.error}
        
        case "LOGOUT":
            return initialUiState
        default:
            return state
    }
}


function user(state = {}, action) {
    switch (action.type) {
        case actions.SET_AUTH_SESSION:
            return {...state, authUser: action.user}
        case actions.SET_LOGIN_TYPE:
            return {...state, userType: action.userType}
            
        case actions.GOT_USER:
            return {...state, userData: action.userData}
        case actions.SAVE_USER:
            return {...state, userData: {...state.userData, ...action.userData}}

        case actions.FINISHED_SETUP:
            window.scrollTo(0,0)
            return {...state, userData: {...state.userData, NEW: false}}


        case "LOGOUT":
            return {}
        default:
            return state
    }
}

// const { 'open': parentValue, ...noChild } = state;
// // Remove the 'doc_id' from the 'open' element
// const { [action.doc_id]: removedValue, ...childWithout } = parentValue;
// // Merge back together
// const withoutEle = { ...noChild, 'open': childWithout };
// return withoutEle;

function AllUsers(state={}, action) {
    switch (action.type) {
        case actions.SAVE_LOCAL_USER:
            return {...state, [action.doc_id]: action.data} 
            
        case actions.REMOVE_LOCAL_USER:
            const { [action.doc_id]: removedValue, ...childWithout } = state;
            return childWithout

                

        case "LOGOUT":
            return {}
        default:
            return state
    }
    
}



function AllProjects(state={}, action) {
    switch (action.type) {
        case actions.SAVE_LOCAL_PROJECT:
            return {...state, [action.doc_id]: action.data} 
            
        // case actions.REMOVE_LOCAL_PROJECT:
        //     const { [action.doc_id]: removedValue, ...childWithout } = state;
        //     return childWithout

                

        case "LOGOUT":
            return {}
        default:
            return state
    }
    
}




function AllVendors(state={}, action) {
    switch (action.type) {
        case actions.SAVE_LOCAL_VENDOR:
            return {...state, [action.doc_id]: action.data}    

        case "LOGOUT":
            return {}
        default:
            return state
    }
}

function AllDepartments(state={}, action) {
    switch (action.type) {
        case actions.SAVE_LOCAL_DEPARTMENT:
            return {...state, [action.doc_id]: action.data}    

        case "LOGOUT":
            return {}
        default:
            return state
    }
}

function AllSeveritys(state={}, action) {
    switch (action.type) {
        case actions.SAVE_LOCAL_SEVERITY:
            return {...state, [action.doc_id]: action.data}    

        case "LOGOUT":
            return {}
        default:
            return state
    }
}




function AllBuildings(state={}, action) {
    switch (action.type) {
        case actions.SAVE_LOCAL_BUILDING:
            return {...state, [action.doc_id]: action.data} 
            
        // case actions.REMOVE_LOCAL_BUILDING:
        //     const { [action.doc_id]: removedValue, ...childWithout } = state;
        //     return childWithout

                

        case "LOGOUT":
            return {}
        default:
            return state
    }
    
}



function AllFloors(state={}, action) {
    switch (action.type) {
        case actions.SAVE_LOCAL_FLOOR:
            return {...state, [action.doc_id]: action.data} 
            
        // case actions.REMOVE_LOCAL_FLOOR:
        //     const { [action.doc_id]: removedValue, ...childWithout } = state;
        //     return childWithout

                

        case "LOGOUT":
            return {}
        default:
            return state
    }
    
}


function AllIssues(state={}, action) {
    switch (action.type) {
        case actions.SAVE_LOCAL_ISSUE:
            return {...state, [action.doc_id]: action.data} 
            
        // case actions.REMOVE_LOCAL_ISSUE:
        //     const { [action.doc_id]: removedValue, ...childWithout } = state;
        //     return childWithout

                

        case "LOGOUT":
            return {}
        default:
            return state
    }
    
}


const initialAppState = {
    minDate: null,
    maxDate: null,
    dateType: "BOTH",
    tableLabels: {"Project": "Location Label 1", "Building": "Location Label 2", "Floor": "Location Label 3", "Vendor": "External Stakeholder Label", "Severity": "Impact Label", "Department": "Internal Stakeholder Label"}
}
function app(state=initialAppState, action) {
    switch (action.type) {
        case 'SET_LOCAL_CLIENT_LOGO':
            return {...state, logoLink: action.link}
            
        case actions.SET_MIN_ISSUE_DATE_SEARCH:
            return {...state, minDate: action.date} 
            
        case actions.SET_MAX_ISSUE_DATE_SEARCH:
            return {...state, maxDate: action.date} 
        
        case actions.SET_TYPE_ISSUE_DATE_SEARCH:
            return {...state, dateType: action.dateType}

        case actions.CLEAR_ISSUE_DATE_SEARCH:
            return initialAppState

        case actions.SAVED_TABLE_LABELS:
            return {...state, LABELS_SAVED: true, tableLabels: action.tableLabels}

        case actions.SET_TABLE_LABELS:

            let tableLabels = initialAppState.tableLabels

            if (action.clientData.Labels) {

                if (action.clientData.Labels.Project) {
                    tableLabels["Project"] = action.clientData.Labels.Project
                }
                if (action.clientData.Labels.Building) {
                    tableLabels["Building"] = action.clientData.Labels.Building
                }
                if (action.clientData.Labels.Floor) {
                    tableLabels["Floor"] = action.clientData.Labels.Floor
                }
                if (action.clientData.Labels.Vendor) {
                    tableLabels["Vendor"] = action.clientData.Labels.Vendor
                }
                if (action.clientData.Labels.Severity) {
                    tableLabels["Severity"] = action.clientData.Labels.Severity
                }
                if (action.clientData.Labels.Department) {
                    tableLabels["Department"] = action.clientData.Labels.Department
                }
            }

            return {...state, 
                ...action.clientData,
                // max_issue_count: action.clientData.max_issue_count, 
                expiry_date: action.clientData.expiry_date ? new Date(action.clientData.expiry_date["seconds"]*1000) : new Date(), 
                // date_created: action.clientData.date_created ? new Date(action.clientData.date_created["seconds"]*1000) : new Date(), 
                // issue_count: action.clientData.issue_count, 
                // NEW_CLIENT: action.clientData.NEW,
                // LABELS_SAVED: action.clientData.LABELS_SAVED,
                tableLabels: tableLabels,
            }
    

        case "LOGOUT":
            return initialAppState
        default:
            return state
    }
}



function Clients(state={}, action) {
    switch (action.type) {
        case "SAVE_LOCAL_CLIENT":
            if (!action.data.size) {
                action.data.size = 0
            }
            return {...state, [action.doc_id]: action.data} 
        
        case "ADD_CLIENT_STORAGE_SIZE":
            const clientData = state[action.client_name];
            if (!clientData["size"]) { clientData["size"] = 0 }
            clientData["size"] = clientData["size"] + action.size
            return {...state, [action.client_name]: clientData} 
            
        // case actions.REMOVE_LOCAL_ISSUE:
        //     const { [action.doc_id]: removedValue, ...childWithout } = state;
        //     return childWithout

                

        case "LOGOUT":
            return {}
        default:
            return state
    }
    
}


const rootReducer = combineReducers({
    ui,
    user,
    app,
    AllUsers,
    AllProjects,
    AllVendors,
    AllBuildings,
    AllFloors,
    AllSeveritys,
    AllDepartments,
    AllIssues,
    loadingBar: loadingBarReducer,
    Clients, // for ADMIN ONLY
    
  })

// const rootReducer = (previousState, action) => previousState;

export default rootReducer